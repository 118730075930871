export {default as Logo} from "assets/images/Logo.png"
export {default as Gif} from "assets/images/Hypernova.gif"
export {default as Model} from "assets/images/model.png"
export {default as Citric} from "assets/images/citric.png"
export {default as GapYoq} from "assets/images/gapyoq.png"
export {default as Gosh} from "assets/images/go'shtbor.png"
export {default as Imarat} from "assets/images/imarat.png"
export {default as Evomed} from "assets/images/evomed.png"
export {default as Generator} from "assets/images/generatorhouse.png"
export {default as Arken} from "assets/images/arken.png"
export {default as Bahra} from "assets/images/bahra.png"
export {default as Bigmetal} from "assets/images/bigmetal.png"
export {default as About1} from "assets/images/about-1.png"
export {default as About2} from "assets/images/about-2.png"